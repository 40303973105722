import { NavbarDesktopMenuItem } from 'components/atoms/NavbarMenuItem';
import WorkshopPreview from 'components/molecules/WorkshopPreview';
import useWorkshop from 'hooks/useWorkshop';
import { take, uniq } from 'lodash';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

WorkshopList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  displayFilter: PropTypes.bool,
  layoutClasses: PropTypes.string,
  maxCount: PropTypes.number
};

export default function WorkshopList({
  title,
  displayFilter = true,
  workshops = [],
  layoutClasses = 'lg:py-8 py-4',
  maxCount,
  emptyViewMessage
}) {
  const { t } = useTranslation();

  const { push } = useRouter();
  const [activeTab, setActiveTab] = useState('all');
  const { workshops: myWorkshops } = useWorkshop();

  function getCategoryList(workshops) {
    return uniq(workshops.map((workshop) => workshop.category)).filter(Boolean);
  }

  function getActiveState(category) {
    return activeTab === category ? 'active' : 'default';
  }

  const categories = useMemo(() => getCategoryList(workshops), [workshops]);
  const workshopList = useMemo(() => {
    const list =
      activeTab === 'all'
        ? workshops
        : workshops.filter((workshop) => workshop.category === activeTab);

    return take(list, maxCount || list?.length || 0);
  }, [workshops, activeTab]);

  function onWorkshopClickHandler(event, workshop) {
    event.preventDefault();
    const workshopId = myWorkshops?.find((w) => w?.displayUrl === workshop.displayUrl)?.id;
    if (workshopId) push(`workshop/${workshopId}`);
    else push(`/w/${workshop?.displayUrl}`);
  }

  if (workshops.length === 0) {
    return (
      <div className={tw('flex items-center justify-center w-full h-full flex-1')}>
        <div className={tw('space-y-2 mb-4 text-center w-full  h-full flex items-center flex-col')}>
          <img
            className={tw('h-32 sm:h-60 w-full')}
            src={'/assets/meeting/girl-chair-pc.svg'}
            alt={'Girl with PC'}
          />

          <h2 className={tw('text-gray-900 text-lg sm:text-xl max-w-md')}>{emptyViewMessage}</h2>
        </div>
      </div>
    );
  }
  return (
    <div id={'eventList'} className={tw('bg-white')}>
      <div className={tw('mx-auto px-4 max-w-7xl sm:px-6 lg:px-8', layoutClasses)}>
        <div className={'space-y-12'}>
          <div className={tw('space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none')}>
            <h2 className={tw('text-3xl font-extrabold tracking-tight sm:text-4xl')}>{title}</h2>
            {displayFilter && (
              <div className={tw('md:flex-row md:space-x-8 flex flex-col')}>
                <NavbarDesktopMenuItem
                  size="lg"
                  onClick={() => setActiveTab('all')}
                  text={t('commons.all')}
                  state={getActiveState('all')}
                />
                {categories.map((category) => (
                  <NavbarDesktopMenuItem
                    key={category}
                    size="lg"
                    onClick={() => setActiveTab(category)}
                    text={category}
                    state={getActiveState(category)}
                  />
                ))}
              </div>
            )}
          </div>
          <ul
            className={tw(
              'space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8'
            )}>
            {workshopList.map((workshop) => (
              <WorkshopPreview
                key={workshop.id}
                workshop={workshop}
                onClick={onWorkshopClickHandler}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
