// import DribbleIcon from 'components/icons/DribbleIcon';
// import FacebookIcon from 'components/icons/FacebookIcon';
// import GithubIcon from 'components/icons/GithubIcon';
// import InstagramIcon from 'components/icons/InstagramIcon';
// import TwitterIcon from 'components/icons/TwitterIcon';
import { NAVIGATION_ROUTES } from 'constants/navigationPaths';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { tw } from 'utils';

FullFooter.propTypes = {
  layoutClasses: PropTypes.string
};

const navigationItems = (t) => ({
  main: [
    { name: t('footer.blog'), href: NAVIGATION_ROUTES.Blog },
    // { name: t('footer.knowledge-base'), href: NAVIGATION_ROUTES.KnowledgeBase },
    // { name: t('footer.careers'), href: NAVIGATION_ROUTES.Careers },
    { name: t('footer.privacy'), href: NAVIGATION_ROUTES.Privacy },
    { name: t('footer.terms'), href: NAVIGATION_ROUTES.Terms }
  ],
  social: [
    // { icon: FacebookIcon, href: '#', name: 'facebook' },
    // { icon: InstagramIcon, href: '#', name: 'instagram' },
    // { icon: TwitterIcon, href: '#', name: 'twitter' },
    // { icon: GithubIcon, href: '#', name: 'github' },
    // { icon: DribbleIcon, href: '#', name: 'dribble' }
  ]
});

export function FullFooter({ layoutClasses = '' }) {
  const { t } = useTranslation();
  const navItems = navigationItems(t);
  return (
    <footer className={tw('bg-white', layoutClasses)}>
      <div className={tw('max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8')}>
        <nav className={tw('-mx-5 -my-2 flex flex-wrap justify-center')} aria-label={'Footer'}>
          {navItems.main.map((item) => (
            <div key={item.name} className={'px-5 py-2'}>
              <a href={item.href} className={tw('text-base text-gray-500 hover:text-gray-900')}>
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className={tw('mt-8 flex justify-center space-x-6')}>
          {navItems.social.map((item) => (
            <a key={item.name} href={item.href} className={tw('text-gray-400 hover:text-gray-500')}>
              <span className="sr-only">{item.name}</span>
              <item.icon className={tw('h-6 w-6')} aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className={tw('mt-8 text-center text-base text-gray-400')}>
          &copy; {t('footer.all-rights-reserved')}
        </p>
      </div>
    </footer>
  );
}

export function MiniFooter() {
  const { t } = useTranslation();
  const navItems = navigationItems(t);
  return (
    <footer className={tw('bg-white')}>
      <div
        className={tw(
          'max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'
        )}>
        <div className={tw('flex justify-center space-x-6 md:order-2')}>
          {navItems.social.map((item) => (
            <a key={item.name} href={item.href} className={tw('text-gray-400 hover:text-gray-500')}>
              <span className="sr-only">{item.name}</span>
              <item.icon />
            </a>
          ))}
        </div>
        <div className={tw('mt-8 md:mt-0 md:order-1')}>
          <p className={tw('text-center text-base text-gray-400')}>
            &copy; {t('footer.all-rights-reserved')}
          </p>
        </div>
      </div>
    </footer>
  );
}
