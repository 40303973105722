import FacebookIcon from 'components/icons/FacebookIcon';
import InstagramIcon from 'components/icons/InstagramIcon';
import LinkedinIcon from 'components/icons/LinkedinIcon';
import TwitterIcon from 'components/icons/TwitterIcon';
import { tw } from 'utils';
import { dateDisplayFormat } from 'utils/dateUtils';
export default function WorkshopPreview({ workshop, onClick }) {
  return (
    <li key={workshop.id}>
      <div className={tw('space-y-4')}>
        <a href={workshop.eventUrl} onClick={(event) => onClick(event, workshop)}>
          <div className={tw('aspect-w-3 aspect-h-2 cursor-pointer')}>
            <img
              className={tw('object-cover shadow-lg rounded-lg')}
              src={workshop.mainImg}
              alt={'workshop'}
            />
          </div>
        </a>
        <div className={tw('space-y-2')}>
          <div className={tw('text-lg leading-6 font-medium space-y-1')}>
            <div className={tw('flex justify-between')}>
              <h3 className={tw('line-clamp-2')}>{workshop?.title}</h3>
            </div>
            <p className={tw('text-indigo-600 line-clamp-3')}>{workshop.summary}</p>

            <div className={tw('flex  text-sm text-gray-500 space-x-4')}>
              <p>
                {workshop.talent?.name ? `${workshop.talent?.name}, ` : ''}
                {/* {workshop?.talent?.title ? `, ${workshop?.talent?.title}` : ''} */}
                <span>
                  <time dateTime={dateDisplayFormat(workshop.date)}>
                    {dateDisplayFormat(workshop.date)}
                  </time>
                </span>
              </p>
            </div>
          </div>
          <ul className={tw('flex space-x-4')}>
            {workshop.facebook && (
              <li>
                <a href={workshop.facebook} className={tw('text-gray-400 hover:text-gray-500')}>
                  <FacebookIcon />
                </a>
              </li>
            )}
            {workshop.twitter && (
              <li>
                <a href={workshop.twitter} className={tw('text-gray-400 hover:text-gray-500')}>
                  <TwitterIcon />
                </a>
              </li>
            )}
            {workshop.linkedin && (
              <li>
                <a href={workshop.linkedin} className={tw('text-gray-400 hover:text-gray-500')}>
                  <LinkedinIcon />
                </a>
              </li>
            )}
            {workshop.instagram && (
              <li>
                <a href={workshop.instagram} className={tw('text-gray-400 hover:text-gray-500')}>
                  <InstagramIcon />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </li>
  );
}
