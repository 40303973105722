import { format, formatDistance, getTime, setHours, setMinutes } from 'date-fns';
const { parseFromTimeZone, formatToTimeZone } = require('date-fns-timezone');

// eslint-disable-next-line prettier/prettier
export const timesOfTheDay = [
  '00:00 AM',
  '00:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '00:00 PM',
  '00:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM'
];

export const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function normalizeDate({ date, startTime, timezone }) {
  const startTimeHour = Number(startTime.split(':')[0]);
  const startTimeMinutes = Number(startTime.split(':')[1].split(' ')[0]);
  const isAfternoon = startTime.split(' ')[1] === 'PM';
  const startTimeFormatted = isAfternoon ? startTimeHour + 12 : startTimeHour;
  const withHours = setHours(date, startTimeFormatted);
  const withMins = setMinutes(withHours, startTimeMinutes);
  return getTime(parseFromTimeZone(withMins, { timeZone: timezone }));
}

export function dateDisplayFormat(date) {
  try {
    const pattern = 'E, LLL do, yyyy - HH:mm a';
    const tzFormat = 'x';
    const formatLocal = Number(formatToTimeZone(date, tzFormat, { timeZone: localZone }));
    return format(formatLocal, pattern);
  } catch {
    return '';
  }
}

export function hasMeetingStarted(date) {
  const tzFormat = 'x';
  const dateToLocal = Number(formatToTimeZone(date, tzFormat, { timeZone: localZone }));
  return dateToLocal < Date.now();
}

export function hasMeetingEnded(date, duration) {
  const tzFormat = 'x';
  const durationInMs = duration * 60 * 60 * 1000;
  const dateToLocal = Number(formatToTimeZone(date, tzFormat, { timeZone: localZone }));
  return dateToLocal + durationInMs < Date.now();
}

export function getTimeAgo(date) {
  try {
    const tzFormat = 'x';
    const normalizedDate = typeof date === 'string' ? date : date.toDate();
    const formatLocal = Number(formatToTimeZone(normalizedDate, tzFormat, { timeZone: localZone }));

    return formatDistance(formatLocal, Date.now());
  } catch {
    return '';
  }
}
