import PropTypes from 'prop-types';
import { tw } from 'utils';

NavbarMobileMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

export function NavbarMobileMenuItem({ to, text, state = 'default' }) {
  const states = {
    active: 'bg-indigo-50 border-indigo-500 text-indigo-700',
    default:
      'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
  };

  const style = tw('block pl-3 pr-4 py-2 border-l-4 text-base font-medium', states[state]);

  return (
    <a href={to} className={style}>
      {text}
    </a>
  );
}

NavbarDesktopMenuItem.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

NavbarDesktopMenuItem.defaultProps = {
  onClick: () => null
};

export function NavbarDesktopMenuItem({ to, text, state = 'default', onClick, size = 'sm' }) {
  const states = {
    active: 'border-indigo-500 text-gray-900',
    default: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
  };

  const textSizes = {
    sm: 'text-sm',
    md: 'text-md',
    lg: 'text-lg'
  };

  const style = tw(
    'cursor-pointer inline-flex items-center px-1 pt-1 border-b-2',
    textSizes[size],
    'font-method',
    states[state]
  );

  function onClickHandler(e) {
    e.preventDefault();
    onClick(text);
  }
  return (
    <a href={to} className={style} onClick={onClickHandler}>
      {text}
    </a>
  );
}
